import React, { useState } from "react";
import { Button } from "@material-tailwind/react";
import { mailPattern, phoneNumber, strongPwd } from "../variables/Constants";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { postServices } from "../apiServices/apiServices";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Helmet } from "react-helmet-async";

function LoginPage() {
  const [fieldData, setFieldData] = useState({ email: "", password: "" });
  const [isValid, setIsValid] = useState({ email: true, password: true });
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleemailChange = (e) => {
    if (mailPattern.test(e.target.value) || phoneNumber.test(e.target.value)) {
      setIsValid({ ...isValid, email: true });
    } else {
      setIsValid({ ...isValid, email: false });
    }
    setFieldData({ ...fieldData, email: e.target.value });
  };

  const handlePasswordChange = (e) => {
    if (strongPwd.test(e.target.value)) {
      setIsValid({ ...isValid, password: true });
    } else {
      setIsValid({ ...isValid, password: false });
    }
    setFieldData({ ...fieldData, password: e.target.value });
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!isValid.email) {
      toast.error("Invalid email or Phone number.");
      setLoading(false);
      return;
    }

    if (!isValid.password) {
      toast.error(
        "Password must have at least 1 lowercase, number, special character, and be a minimum of 8 characters."
      );
      setLoading(false);
      return;
    }
    try {
      const res = await postServices(`/api/auth/login`, {
        email: fieldData.email,
        password: fieldData.password,
      });
      
      setLoading(false);
      if (res.responseCode === 200) {
      navigate("/admin/manage_customer_review");
        sessionStorage.setItem(
          "khadoom-frontend-admin-token",
          res.responseData.access_token
        );
      } else {
        toast.error(res.responseMessage || "Login failed.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Login Error:", error);
      toast.error(error.message || "An error occurred during login.");
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="login-section">
      <Helmet>
        <title>Khadoom | Login</title>
      </Helmet>
      <div className="flex items-center justify-center h-screen px-5 sm:px-12 py-12 sm:py-8 rounded-[50px]">
        <div className="hidden md:flex justify-center"></div>
        <form
          className="flex flex-col text-center gap-4 w-full max-w-md "
          onSubmit={handleLoginSubmit}
        >
          <p className="font-semibold text-5xl font-gotham text-white">
            Welcome In
          </p>
          <p className="text-white font-normal mb-5 sm:mb-8 font-gotham">
            Please login to your account
          </p>

          <div>
            <input
              className="w-full px-6 py-3 bg-transparent border-2 outline-none border-[#E2E301] rounded-full font-gotham font-light text-[#E2E301] input"
              placeholder="Email address or Phone *"
              type="text"
              onChange={handleemailChange}
              value={fieldData.email}
              required
            />
          </div>
          <div className="relative">
            <input
              className="w-full border-[#E2E301] outline-none px-6 py-3 pr-10 bg-transparent border-2 rounded-full font-gotham font-light text-[#E2E301] input"
              placeholder="Password *"
              type={isPasswordVisible ? "text" : "password"}
              onChange={handlePasswordChange}
              value={fieldData.password}
              required
            />
            {isPasswordVisible ? (
              <FiEyeOff
                onClick={togglePasswordVisibility}
                className="absolute right-5 top-1/2  transform -translate-y-1/2 text-[#E2E301] cursor-pointer"
              />
            ) : (
              <FiEye
                onClick={togglePasswordVisibility}
                className="absolute right-5 top-1/2  transform -translate-y-1/2 text-[#E2E301] cursor-pointer"
              />
            )}
          </div>
          <div className="flex justify-end items-center">
            <Button
              type="submit"
              className="uppercase font-gotham font-light text-[#fff] px-12 py-2 border-2 border-[#E2E301] rounded-full hover:bg-[#E2E301] hover:text-[#1818EC] bg-transparent outline-none  tracking-[3px] disabled:cursor-not-allowed"
              disabled={loading}
            >
              {loading ? "Loading..." : "Login"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default LoginPage;
