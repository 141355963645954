import React, { useEffect, useState } from "react";
import KhadoomPhone from "../../../assets/khadoom-phone.png";
import FeaDots from "../../../assets/fea-dots.png";
import FeaMsg from "../../../assets/fea-msg.png";
// import SecOneImg from "../../../assets/fea-sec-one 2.png";
import PersonalImg from "../../../assets/personal-img.png";
import ParcelImg from "../../../assets/parcel-img.png";
import LaundryImg from "../../../assets/laundry-img.png";
import PetImg from "../../../assets/pet-img.png";
import StarBanImg from "../../../assets/star-ban-img.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Scrollbar } from "swiper/modules";
import { Rating } from "@material-tailwind/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import toast from "react-hot-toast";
import { getServices } from "../../../apiServices/apiServices";
import config from "../../../config.json";
import AOS from "aos";
import "aos/dist/aos.css";

function Features() {
  const [ratingList, setRatingList] = useState([]);
  const url = config.url;

  useEffect(() => {
    fetchRatingsList();

    AOS.init({
      duration: 1200,
      delay: 0, // Customize the animation duration (in milliseconds)
      // once: true, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const fetchRatingsList = async () => {
    try {
      const res = await getServices(`/api/review/list`);
      if (res.responseCode === 200) {
        setRatingList(res.responseData);
      } else {
        setRatingList([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div>
      <div className="relative w-full overflow-hidden pt-16 sm:pt-20 md:pt-24 lg:pt-32 feature-section">
        <div className="max-w-[1300px] mx-auto">
          <div
            className="relative pb-12 sm:pb-20 md:pb-28 lg:pb-32 px-2 xl:px-0"
            id="about-us"
            data-aos="fade-up"
          >
            <div className="relative z-10">
              <p className="uppercase text-white font-gotham  font-bold text-[32px] sm:text-[65px] md:text-[80px] lg:text-[100px] xl:text-[128px] leading-[1]">
                Your friendly <br className="block sm:block" /> A.I powered
                <br className="block sm:block" />
                Assistant
              </p>

              <p className="text-[14px] text-white sm:text-[17px] md:text-[19px] pt-8 font-gotham font-light text-justify">
                I'm Khadoom, Qatar's first <br className="block md:hidden" />{" "}
                AI-powered personal assistant,
                <br className="block md:hidden" />
                designed to ease
                and simplify<br className="block md:block" />  customers' lives.{" "}
               
                In operation  <br className="block md:hidden" />across Qatar,I
                fulfill every task that <br className="block md:block" />
                
                customers need completed-from
                on-demand delivery, to pet
                services, 
                <br className="hidden md:block" />
                to grocery & personal shopping and much more. 
                <br className="hidden md:hidden" />
                Your life just got easier.
              </p>
              <button className="uppercase font-gotham font-light text-white border-2 border-white rounded-full px-7 py-1 mt-8 hover:bg-[#1818EC] hover:border-[#1818EC]">
                Know more
              </button>
            </div>
            {/* <div className="absolute -right-[150px] sm:-right-[230px] xl:-right-[320px] -top-[15px] sm:-top-[40px]">
              <img
                src={SecOneImg}
                alt=""
                className="w-[400px] sm:w-[500px] md:w-[650px] lg:w-[750px] xl:w-[850px] wiggle-img-mobil"
              />
            </div> */}
            <div className="absolute right-[12px] sm:right-[0px] md:right-[90px] lg:right-[45px] xl:right-[5px] -top-[25px] sm:top-[32px] lg:-top-[32px] z-10">
              <img
                src={FeaMsg}
                alt=""
                className="w-[50px] lg:w-[150px] xl:w-[210px] wiggle-img"
              />
            </div>
            <div className="absolute -right-[15px] sm:-right-[0px] lg:-right-[0px] xl:-right-[50px] -top-[15px] sm:top-[50px] md:-top-[50px] z-10">
              <img
                src={FeaDots}
                alt=""
                className="w-[250px] sm:w-[300px] md:w-[450px] lg:w-[550px] xl:w-[700px] wiggle-img"
              />
            </div>
            <div className="absolute -right-[150px] sm:-right-[230px] lg:-right-[280px] xl:-right-[320px] top-[0px] sm:top-[80px] xl:top-[140px]">
              <img
                src={KhadoomPhone}
                alt=""
                className="w-[340px] sm:w-[500px] md:w-[650px] lg:w-[750px] xl:w-[850px] wiggle-img-mobil"
              />
            </div>
          </div>

          <div className="relative" id="features">
            <p
              data-aos="fade-up"
              className="uppercase text-white font-gotham font-bold text-[40px] sm:text-[50px] md:text-[65px] lg:text-[77px] xl:text-[85px] px-2 xl:px-0 leading-[0.9]"
            >
              Features
            </p>

            <div
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-3 my-4 px-2 xl:px-0"
              data-aos="fade-up"
            >
              <div className="feature-card">
                <div className="bg-white rounded-tl-[40px] rounded-tr-[40px]">
                  <img
                    src={PersonalImg}
                    alt=""
                    className="w-[210px] sm:w-[250px] mx-auto sm:mx-auto h-auto object-cover py-3 lg:py-8"
                  />
                </div>
                <div className="bg-[#0000EC] py-4 rounded-bl-[40px] rounded-br-[40px]">
                  <p className="uppercase text-[#E2E301] font-gotham text-center text-base md:text-sm lg:text-base">
                    Personal
                    <br /> Shopping
                  </p>
                </div>
              </div>

              <div className="feature-card">
                <div className="bg-white rounded-tl-[40px] rounded-tr-[40px]">
                  <img
                    src={ParcelImg}
                    alt=""
                    className="w-[210px] sm:w-[250px] mx-auto sm:mx-auto h-auto object-cover py-3 lg:py-8"
                  />
                </div>
                <div className="bg-[#0000EC] py-4 rounded-bl-[40px] rounded-br-[40px]">
                  <p className="uppercase text-[#E2E301] font-gotham text-center text-base md:text-sm lg:text-base">
                    Parcel
                    <br /> Delivery
                  </p>
                </div>
              </div>

              <div className="feature-card">
                <div className="bg-white rounded-tl-[40px] rounded-tr-[40px]">
                  <img
                    src={LaundryImg}
                    alt=""
                    className="w-[210px] sm:w-[250px] mx-auto sm:mx-auto h-auto object-cover py-3 lg:py-8"
                  />
                </div>
                <div className="bg-[#0000EC] py-4 rounded-bl-[40px] rounded-br-[40px]">
                  <p className="uppercase text-[#E2E301] font-gotham text-center text-base md:text-sm lg:text-base">
                    Laundry pick up &
                    <br /> Drop off
                  </p>
                </div>
              </div>

              <div className="feature-card">
                <div className="bg-white rounded-tl-[40px] rounded-tr-[40px]">
                  <img
                    src={PetImg}
                    alt=""
                    className="w-[210px] sm:w-[250px] mx-auto sm:mx-auto h-auto object-cover py-3 lg:py-8"
                  />
                </div>
                <div className="bg-[#0000EC] py-4 rounded-bl-[40px] rounded-br-[40px]">
                  <p className="uppercase text-[#E2E301] font-gotham text-center text-base md:text-sm lg:text-base">
                    Pet pick up &
                    <br /> Drop off
                  </p>
                </div>
              </div>
            </div>

            <div
              className={`grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-0 pt-8 md:pt-16 px-2 xl:px-0 ${
                ratingList.length > 0 ? "pb-4" : "pb-16"
              }`}
            >
              <div
                className="font-gotham font-bold uppercase"
                data-aos="fade-right"
              >
                <p className="text-[24px] md:text-[23px] lg:text-[30px]">
                  Price starts from
                </p>
                <p className="text-[#1818EC] text-[60px] md:text-[80px] lg:text-[105px] leading-[0.9]">
                  10 Qr
                </p>
              </div>
              <div
                className="col-span-1 md:col-span-2 text-justify font-gotham font-light p-2"
                data-aos="fade-left"
              >
                <p className="text-sm text-white lg:text-[18px] leading-[23px] pt-2">
                  I can fulfill a variety of tasks and chores for our customers.
                  A few examples of such tasks are on-demand delivery, personal
                  shopping (groceries, clothing, electronics, beauty products,
                  and more), parcel & laundry pick up, pet services, and
                  delivery of food, gifts, documents & medication. If you are
                  unsure the task can be done, all you have to do is text me on
                  WhatsApp and I will confirm.
                </p>
              </div>
            </div>

            {ratingList.length > 0 && (
              <div
                className="relative pt-12 md:pt-20 lg:pt-28 pb-8 md:pb-16 px-2 xl:px-0"
                id="customer-reviews"
              >
                <p
                  data-aos="fade-right"
                  className="uppercase text-white font-gotham font-bold text-[33px] sm:text-[40px] md:text-[65px] lg:text-[77px] xl:text-[85px] leading-[1] sm:leading-[0.9] relative z-10"
                >
                  What our
                  <br /> Customers say <br /> about me
                </p>
                <div className="absolute top-0 right-[-15px] md:-right-[30px] lg:-right-[50px] xl:-right-[50px] -rotate-[30deg]">
                  <img
                    src={StarBanImg}
                    alt=""
                    className="w-[220px] sm:w-[280px] md:w-[420px] lg:w-[480px] xl:w-[510px] wiggle-img"
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {ratingList.length > 0 && (
          <div data-aos="fade-left">
            <Swiper
              slidesPerView={3}
              centeredSlides={false}
              slidesPerGroupSkip={1}
              grabCursor={true}
              keyboard={{
                enabled: true,
              }}
              breakpoints={{
                1200: {
                  slidesPerView: 3,
                  slidesPerGroup: 2,
                },
                769: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                300: {
                  slidesPerView: 1,
                  slidesPerGroup: 2,
                },
              }}
              scrollbar={false}
              modules={[Keyboard, Scrollbar]}
              className="mySwiper pt-8 pb-28"
            >
              {ratingList.map((item, i) => (
                <SwiperSlide key={i}>
                  <div className="relative bg-white pt-12 pb-8 px-6 rounded-[35px]">
                    <img
                      src={url + item.image}
                      alt=""
                      className="absolute -top-10 left-[40%] z-10 w-[90px] h-[90px] rounded-full border-4 drop-shadow-lg border-white"
                    />
                    <div className="flex justify-center items-center py-4">
                      <Rating
                        className="text-yellow-500 flex"
                        ratedColor="amber"
                        value={Number(item.rating)}
                        readonly
                      />
                    </div>
                    <p className="font-gotham font-light text-center">
                      {item.description ?? "-"}
                    </p>

                    <p className="text-center font-gotham text-[17px] pt-4">
                      {item.name ?? "-"}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
}

export default Features;
